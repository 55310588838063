import { ImageProps, SrcSet } from "./types";
import classNames from "classnames";

export const Image = ({
  alt,
  sources,
  lowResImageSrc,
  src,
  width,
  height,
  objectFit,
  className,
  objectPosition,
}: ImageProps): JSX.Element => {
  const objectFitPositon = {
    objectFit,
    objectPosition,
  };

  const getSrcSets = () => {
    return sources
      ? Object.entries(sources).filter((entry) => entry[1].srcset)
      : [];
  };

  return (
    <picture>
      {getSrcSets().length ? (
        getSrcSets().map((srcSet: SrcSet) => (
          <source
            key={srcSet[0]}
            data-srcset={srcSet[1].srcset}
            type={srcSet[1].type}
          />
        ))
      ) : (
        <source data-srcset={src} />
      )}
      <img
        src={lowResImageSrc ? lowResImageSrc : src}
        data-src={src}
        alt={alt}
        className={classNames(
          "lazyload max-w-full",
          className,
          {"w-full": !(width || height) }
        )}
        style={{ height, width, ...objectFitPositon }}
      />
    </picture>
  );
};
