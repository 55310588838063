import React, { useState } from "react";
import "../../assets/scss/Components/Modal/modal.scss";
import { Button } from "../Button/Button";
import { VarianButtonEnum } from "../../enum/Button/variantButton.model";
import { colorButton } from "../../enum/Button/colorButton.model";
import { Typography } from "../../components/typography";
import { IconModelEnum } from "../../enum/Button/iconButton.model";
import DataContainer from "../../services/DataContainer";
import {
  ContactDealerFormSettings,
  ContactDealerSettings,
  PopupSendEmail,
  PopupShare,
  PrivacyPolicySettings
} from "../../enum/enums";
import { CustomCheckbox } from "../CustomCheckbox/CustomCheckbox";
import classNames from "classnames";
import { Dropdown } from "../Dropdown/Dropdown";
import { Input } from "../Input/Input";
import { mailFormData, mailFormDataTypes } from "./mailData";

export const Modal = ({ setIsOpen, contactFormModalOpen, setContactFormModalOpen, configuration, serviceActiveCampaign, frontalTruckImage, locale}: any) => {  
  const [emailValue, setEmailValue] = useState("");
  const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
  const [hasErrors, setHasErrors] = useState(false);
  const [privacyCheckboxErrorIndicator, setPrivacyCheckboxErrorIndicator] = useState(false);
  const [newsCheckboxErrorIndicator, setNewsCheckboxErrorIndicator] = useState(false);
  const [marketingCheckboxErrorIndicator, setMarketingCheckboxErrorIndicator] = useState(false);
  const [step, setStep] = useState(1);
  const [mailModalData, setMailModalData] = useState(mailFormData);
  const pattern = /[a-zA-Z0-9]+[\\.]?([a-zA-Z0-9]+)?[\\@][a-z]{2,20}[\\.][a-z]{2,9}/g;
  const currentSegment = localStorage.getItem("segment");
  
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMailModalData({ ...mailModalData, [e.target.name]: e.target.value });
  };

  const onChangeDropdown = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setMailModalData({ ...mailModalData, [e.target.name]: e.target.value });
  };

  const market = locale?.split("-")[1];

  const checkEmailValidity = (email: string) => {
    const result = pattern.test(email);
    setIsEmailValid(result);
  };

  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    if (name === "privacyCheckbox") {
      setPrivacyCheckboxErrorIndicator(e.target.checked);
    } else if (name === "newsCheckbox") {
      setNewsCheckboxErrorIndicator(e.target.checked);
    } else if (name === "marketingCheckbox") {
      setMarketingCheckboxErrorIndicator(e.target.checked);
    }
    setMailModalData({ ...mailModalData, [e.target.name]: e.target.checked });
  };

  const requiredFielsAreNotEmpty = (data: mailFormDataTypes) => {
    const requiredFields = Object.entries(data).filter(([key, value]) => {
      return DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, `contact_dealer_${key}_required`) === "true";
    });

    return requiredFields.every(field => field[1] !== "");
  };

  const submitHandler = async () => {
    const marketingCheckboxRequired = DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_OPTIN_MARKETING_CHECKBOX_REQUIRED) === "true";    
    if(DataContainer.sectionKey(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.SALESFORCE) === "true") {      
      const requestedFrom = "MailForm";      
      checkEmailValidity(mailModalData.email);
      if(!requiredFielsAreNotEmpty(mailModalData) || !mailModalData.email || !isEmailValid || !mailModalData.privacyCheckbox || !mailModalData.marketingCheckbox) {
        setHasErrors(true);
      } else {
        setHasErrors(false);        
        DataContainer.sendDataToSalesForce(mailModalData, requestedFrom, locale, configuration, frontalTruckImage, async (res: string) => {                  
          if(res) {
            setStep(2);
          } else {
            console.error("Something went wrong with your request");
          }
        });        
      }
    } else {      
      checkEmailValidity(emailValue);
      if (!emailValue || !isEmailValid || (serviceActiveCampaign === "true" ? (!newsCheckboxErrorIndicator && !privacyCheckboxErrorIndicator) : marketingCheckboxRequired ? (!marketingCheckboxErrorIndicator || !privacyCheckboxErrorIndicator) : !privacyCheckboxErrorIndicator) ) {
        setHasErrors(true);
      }
      else {
        setHasErrors(false);
        setStep(2);
        DataContainer.sendDataToAdobeDataLayer("truckbuilder_summary_email-configuration");
        await DataContainer.sendTruckConfigurationToCostumer(configuration, emailValue, serviceActiveCampaign, frontalTruckImage, newsCheckboxErrorIndicator, locale);
      }
    }
  }; 
  
  return (
    <>
      {!contactFormModalOpen ? (
        <div className="email-modal h-full top-2/4 left-2/4 z-30 fixed -translate-y-1/2 -translate-x-1/2">
          <div className=" flex flex-col flex-wrap fixed top-1/2 left-1/2 -translate-y-1/2 max-h-screen -translate-x-1/2">
            <div className={(`${(step === 1 && DataContainer.sectionKey(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.SALESFORCE) === "true")
              ? "email-modal__salesforce" : step === 1 ? "email-modal__content" : "thank-you--modal"} bg-white border border-gray-suva relative`)}>
              {step === 1 && 
              <div className="p-6">
                <div className="relative flex flex-row-reverse justify-between">
                  <Button
                    variant={VarianButtonEnum.DEFAULT}
                    icon={IconModelEnum.CLOSE_GREY}
                    bgColor={colorButton.WHITE}
                    onClickEvent={() => setIsOpen(false)}
                    customClassNames="border p-2 cursor-pointer opacity-50  border border-gray-stone right-3 sm:right-6 top-5"
                  />
                  <div className="flex items-center h-6 text-gray-charcoal uppercase text-2xl font-bold ">
                    {DataContainer.sectionKey(
                      PopupSendEmail.SEND_EMAIL,
                      PopupSendEmail.SEND_EMAIL_TEXT
                    )}
                  </div>
                </div>
                <div className="pt-5">
                  <form className="ng-untouched ng-pristine ng-invalid">
                    {DataContainer.sectionKey(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.SALESFORCE) === "true" ? 
                      <div className="flex flex-col pb-3">
                        <Input
                          variant="mailForm"
                          type="text"
                          name="firstname"
                          label={DataContainer.sectionKey(
                            ContactDealerFormSettings.CONTACT_DEALER_FORM,
                            ContactDealerFormSettings.CONTACT_DEALER_FIRSTNAME_TEXT
                          )}
                          value={mailModalData.firstname}
                          onChangeEvent={onChange}
                          isRequired={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_FIRSTNAME_REQUIRED) === "true" && hasErrors}
                        />
                        <Input
                          variant="mailForm"
                          type="text"
                          name="lastname"
                          label={DataContainer.sectionKey(
                            ContactDealerFormSettings.CONTACT_DEALER_FORM,
                            ContactDealerFormSettings.CONTACT_DEALER_LASTNAME_TEXT
                          )}
                          value={mailModalData.lastname}
                          onChangeEvent={onChange}
                          isRequired={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_LASTNAME_REQUIRED) === "true" && hasErrors}
                        />
                        <Input
                          variant="mailForm"
                          type="email"
                          name="email"
                          label={DataContainer.sectionKey(
                            ContactDealerFormSettings.CONTACT_DEALER_FORM,
                            ContactDealerFormSettings.CONTACT_DEALER_EMAIL_TEXT
                          )}
                          value={mailModalData.email}
                          onChangeEvent={onChange}
                          isRequired={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_EMAIL_REQUIRED) === "true" && hasErrors}
                        />
                        {(!isEmailValid && mailModalData.email !== "") &&
                          <span className="text-red text-xl h-4">{DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_EMAIL_INVALID_TEXT)}</span>
                        }
                        {/* From here
                            Also some fields that are not required, we made them as required by replacing "&&" with "||", which means only "hasErrors", will trigger error text */}
                        <Input
                          variant="mailForm"
                          type="number"
                          name="phone"
                          label={DataContainer.sectionKey(
                            ContactDealerFormSettings.CONTACT_DEALER_FORM,
                            ContactDealerFormSettings.CONTACT_DEALER_PHONE_TEXT
                          )}
                          onKeyDownEvent={(evt) => ["e", "E"].includes(evt.key) && evt.preventDefault()}
                          value={mailModalData.phone}
                          onChangeEvent={onChange}
                          isRequired={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_PHONE_REQUIRED) === "true" && hasErrors}
                        />
                        <Input
                          variant="mailForm"
                          type="text"
                          name="company"
                          label={DataContainer.sectionKey(
                            ContactDealerFormSettings.CONTACT_DEALER_FORM,
                            ContactDealerFormSettings.CONTACT_DEALER_COMPANY_TEXT
                          )}
                          value={mailModalData.company}
                          onChangeEvent={onChange}
                          isRequired={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_COMPANY_REQUIRED) === "true" && hasErrors}
                        />
                        <Dropdown
                          variant="secondary"
                          label={DataContainer.sectionKey(
                            ContactDealerFormSettings.CONTACT_DEALER_FORM,
                            ContactDealerFormSettings.CONTACT_DEALER_SEGMENT_TEXT
                          )}
                          name="salesforceSegment"
                          selectedOption={mailModalData?.salesforceSegment}
                          onChangeEvent={onChangeDropdown}
                          options={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.SALESFORCE_SEGMENT_LIST).split(",")}
                          isRequired={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_SEGMENT_REQUIRED) === "true" && hasErrors}
                        />
                        {DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_SHOW_ROLE_LIST) === "true" &&
                          <Dropdown
                            variant="secondary"
                            label={DataContainer.sectionKey(
                              ContactDealerFormSettings.CONTACT_DEALER_FORM,
                              ContactDealerFormSettings.CONTACT_DEALER_ROLE_TEXT
                            )}
                            name="role"
                            selectedOption={mailModalData?.role}
                            onChangeEvent={onChangeDropdown}
                            options={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_ROLE_LIST).split(",")}
                            isRequired={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_ROLE_REQUIRED) === "true" && hasErrors}
                          />                       
                        }
                        <Input
                          variant="mailForm"
                          type="textarea"
                          name="message"
                          label={DataContainer.sectionKey(
                            ContactDealerFormSettings.CONTACT_DEALER_FORM,
                            ContactDealerFormSettings.CONTACT_DEALER_MESSAGE_TEXT
                          )}
                          value={mailModalData.message}
                          onChangeEvent={onChange}
                          isRequired={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_MESSAGE_REQUIRED) === "true" && hasErrors}
                        />
                      </div>
                      : 
                      <div className="flex flex-col pb-3">
                        <label
                          htmlFor="email text-light-grey">{DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_EMAIL_TEXT)}:</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={emailValue}
                          onChange={e => setEmailValue(e.target.value)}
                          className="border py-1.5 border-gray-spunpearl" 
                        />
                        {!emailValue && hasErrors && (
                          <Typography tag="p" className="text-2xs text-red pb-7">
                            {DataContainer.sectionKey(
                              ContactDealerFormSettings.CONTACT_DEALER_FORM,
                              ContactDealerFormSettings.CONTACT_DEALER_EMAIL_REQUIRED_TEXT
                            )}
                          </Typography>
                        )}
                        {!isEmailValid && emailValue && (
                          <Typography tag="p" className="text-2xs text-red pb-7">
                            {DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_EMAIL_INVALID_TEXT)}
                          </Typography>
                        )}
                      </div>
                    }
                    <div className="mb-6 sm:mb-2 w-full text-gray-charcoal">
                      { DataContainer.sectionKey(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.ACTIVE_CAMPAIGN) === "true" ? (
                        <a href={`https://www.volvotrucks.${locale === "en-en" ? "com" : market}/${locale}/tools/privacy.html`} 
                          className="underline underline-offset-1 text-2xl text-blue-700 mb-4" target="_blank" rel="noreferrer">Privacy Statement</a>) 
                        : (
                          <div className="mb-6 sm:mb-2 w-3/4 text-gray-charcoal">
                            {DataContainer.sectionKey(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.SALESFORCE) === "true" ? 
                              <p className="private_policy_text text-gray-charcoal">
                                {DataContainer.sectionKey(
                                  PrivacyPolicySettings.PRIVACY_POLICY,
                                  PrivacyPolicySettings.PRIVACY_POLICY_TEXT
                                )}<span><a href={`https://www.volvogroup.com/en/tools/privacy/privacy-${locale === ("nl-be" || "fr-be") ? locale?.split("-")[0] : market}.html`} target="_blank" rel="noreferrer" className="underline underline-offset-1 text-2xl text-blue-700 mb-4" >{`(https://www.volvogroup.com/en/tools/privacy/privacy-${market}.html)`}</a></span>
                              </p> :
                              <div>
                                <h5 className="private_policy_title">
                                  {DataContainer.sectionKey(
                                    PrivacyPolicySettings.PRIVACY_POLICY,
                                    PrivacyPolicySettings.PRIVACY_POLICY_TITLE
                                  )}
                                </h5>
                                <p className="private_policy_text text-gray-charcoal">
                                  {DataContainer.sectionKey(
                                    PrivacyPolicySettings.PRIVACY_POLICY,
                                    PrivacyPolicySettings.PRIVACY_POLICY_TEXT
                                  )}
                                </p>
                              </div>
                            }
                          </div>
                        )
                      }
                    </div>
                    <div className="flex flex-col gap-4 sm:gap-0">
                      <CustomCheckbox
                        name="privacyCheckbox"
                        customClassNames={classNames({
                          "border-red": !privacyCheckboxErrorIndicator && hasErrors,
                        })}
                        customLabelClasses={classNames({
                          "text-red": !privacyCheckboxErrorIndicator && hasErrors,
                        })}
                        label={DataContainer.sectionKey(
                          ContactDealerFormSettings.CONTACT_DEALER_FORM,
                          ContactDealerFormSettings.CONTACT_DEALER_OPTIN_READ_AND_AGREE_PRIVACY_TEXT
                        )}
                        onChangeEvent={(e) => onCheckboxChange(e, "privacyCheckbox")}
                      />
                      {(DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_OPTIN_MARKETING_CHECKBOX) === "true" || DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_SHOW_ROLE_LIST) === "true") &&
                        <CustomCheckbox
                          name="marketingCheckbox"
                          label={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_OPTIN_MARKETING_CHECKBOX_TEXT)}
                          onChangeEvent={(e) => onCheckboxChange(e, "marketingCheckbox")}
                          customClassNames={classNames({
                            "border-red": !marketingCheckboxErrorIndicator && hasErrors && DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_OPTIN_MARKETING_CHECKBOX_REQUIRED) === "true",
                          })}
                          customLabelClasses={classNames({
                            "text-red": !marketingCheckboxErrorIndicator && hasErrors && DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CONTACT_DEALER_OPTIN_MARKETING_CHECKBOX_REQUIRED) === "true",
                          })}
                        />
                      }
                      {DataContainer.sectionKey(ContactDealerSettings.CONTACT_DEALER_SETTINGS, ContactDealerSettings.ACTIVE_CAMPAIGN) === "true" &&
                        <div className="pt-4 md:pt-0">
                          <CustomCheckbox
                            name="newsCheckbox"
                            customClassNames={classNames({
                              "border-red": !newsCheckboxErrorIndicator && hasErrors,
                            })}
                            customLabelClasses={classNames({
                              "text-red": !newsCheckboxErrorIndicator && hasErrors,
                            })}
                            label={DataContainer.sectionKey(
                              ContactDealerFormSettings.CONTACT_DEALER_FORM,
                              ContactDealerFormSettings.CONTACT_DEALER_OPTIN_VOLVO_TRUCKS_NEWS_TEXT
                            )}
                            onChangeEvent={(e) => onCheckboxChange(e, "newsCheckbox")}
                          />
                        </div>
                      }
                    </div>
                    <div className="flex justify-end mt-2">
                      <Button
                        customClassNames="ml-6 mt-5 p-5 sm:m-0"
                        variant={VarianButtonEnum.SENDBUTTON}
                        text={DataContainer.sectionKey(PopupSendEmail.SEND_EMAIL, PopupSendEmail.SEND_EMAIL_CONFIRM_BUTTON)}
                        textTransform={true}
                        rounded={false}
                        bgColor={colorButton.BLUE_LIGHT}
                        onClickEvent={() => submitHandler()}
                      />
                    </div>
                  </form>
                </div>
              </div>}
              {step === 2 && <div className="p-6">
                <Button
                  variant={VarianButtonEnum.DEFAULT}
                  icon={IconModelEnum.CLOSE_GREY}
                  bgColor={colorButton.WHITE}
                  onClickEvent={() => setIsOpen(false)}
                  customClassNames="absolute z-1 border p-2 cursor-pointer opacity-50  border border-gray-stone right-6 top-6"
                />
                <div className="flex flex-col items-center justify-center mt-10">
                  <Typography tag="h3" className="text-2xl mb-6 text-light-grey">
                    {DataContainer.sectionKey(PopupShare.POPUP_SHARE, PopupShare.SHARE_SENT_TEXT)}
                  </Typography>
                  <Button
                    customClassNames="ml-6 mt-8"
                    variant={VarianButtonEnum.SENDBUTTON}
                    text={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CLOSE_BUTTON_TEXT)}
                    textTransform={true}
                    rounded={false}
                    bgColor={colorButton.BLUE_LIGHT}
                    onClickEvent={() => setIsOpen(false)}
                  />
                </div>
              </div>}
            </div>
          </div>
        </div>) : (
        <div className="email-modal h-full top-2/4 left-2/4 z-30 fixed -translate-y-1/2 -translate-x-1/2">
          <div className="fixed top-2/4 left-2/4 -translate-y-1/2	 -translate-x-1/2">
            <div className={`${contactFormModalOpen === true ? "thank-you--modal" : ""} bg-white border border-gray-suva relative`}>
              {
                contactFormModalOpen &&
                <div className="p-6">
                  <Button
                    variant={VarianButtonEnum.DEFAULT}
                    icon={IconModelEnum.CLOSE_GREY}
                    bgColor={colorButton.WHITE}
                    onClickEvent={() => setContactFormModalOpen(false)}
                    customClassNames="absolute z-1 border p-2 cursor-pointer opacity-50  border border-gray-stone right-6 top-6"
                  />
                  <div className="flex flex-col items-center justify-center mt-10">
                    <Typography tag="h3" className="text-2xl mb-6 text-light-grey">
                      {DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.MESSAGE_SENT_TEXT)}
                    </Typography>
                    <Button
                      customClassNames="ml-6 mt-8"
                      variant={VarianButtonEnum.SENDBUTTON}
                      text={DataContainer.sectionKey(ContactDealerFormSettings.CONTACT_DEALER_FORM, ContactDealerFormSettings.CLOSE_BUTTON_TEXT)}
                      textTransform={true}
                      rounded={false}
                      bgColor={colorButton.BLUE_LIGHT}
                      onClickEvent={() => setContactFormModalOpen(false)}
                    />
                  </div>
                </div>
              }
            </div>
          </div>
        </div>)
      }
    </>
  );
};
