import { CSSProperties } from "react";

export const pdfStyle = {
  container: {
    overflow: "hidden",
    width: "595.28px",
    height: "842px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  page: {
    letterSpacing: "0.05em",
    overflow: "hidden",
    height: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  } as CSSProperties,
  hero: {
    display: "flex",
    marginLeft: "-80px",
    minHeight:"355px",
    backgroundColor: "#fafafa",
    filter: "brightness(99%)",
    marginBottom: "0px !important",
  } as CSSProperties,
  heroImg: {
    width: "100%",
    display: "block",
    objectFit: "fill",
    objectPosition: "50% 50%",
  } as CSSProperties,
  heroImageWrap: {
    width: "65%",
    display: "flex",
    alignItems: "center",
    padding: "0 20px",
  },
  headerLogo: {
    padding: "35px",
    width: "165px",
    height: "10px",
    display: "flex",
    alignItems: "center",
    margin: "0 auto",
  } as CSSProperties,
  logo: {
    object: "contain",
  } as CSSProperties,
  selectedVariants: {
    display: "grid",
    gridTemplateColumns:  "repeat(2, 1fr)",
    padding: "14px 5px 14px 0",
    gap: "20px",
    wordBreak: "break-word",
    width: "35%",
    margin: "0",
  } as CSSProperties,
  selectedVariantsLi: {
    fontFamily: "VolvoNovum-Regular",
    listStyle: "none",
    fontSize: "9px",
    color: "#202529",
  },
  selectedVariantsLiH3: {
    fontSize: "11px",
    fontFamily: "VolvoNovum-Medium",
    color: "#202529",
  },
  interior: {
    height: "322px",
  },
  interiorImg: {
    flex: "none",
    objectFit: "cover",
    height: "100%",
    width: "100%",
    objectPosition: "center",
  } as CSSProperties,
  productTitleWrap: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "0",
  },
  productTitle: {
    fontFamily: "VolvoNovum-Medium",
    flex: "none",
    fontSize: "20px",
    padding: "20px 0 25px",
    fontStretch: "ultra-condensed",
    textTransform: "uppercase",
    fontWeight: "700",
    color: "#212529",
  } as CSSProperties,
  categories: {
    fontFamily: "VolvoNovum-Regular",
    padding: "24px",
  },
  categoriesH4: {
    fontFamily: "VolvoNovum-Regular",
    marginBottom: "40px",
    fontSize: "14px",
    fontWeight: "normal",
    color: "#212529",
    textAlign: "center",
  } as CSSProperties,
  variantsList: {
    fontFamily: "VolvoNovum-Regular",
    display: "grid",
    gridTemplateColumns:  "repeat(3, 1fr)",
    gap: "24px"
  } as CSSProperties,
  variantItem: {
    fontFamily: "VolvoNovum-Regular",
  } as CSSProperties,
  variantWrap: {
    width: "100%",
    height: "161px",
    background: "#eee",
    padding: "50px",
    marginBottom: "10px",
  } as CSSProperties,
  variantImg: {
    maxWidth: "100%",
    height: "100%",
    margin: "0 auto",
    objectPosition: "center",
    objectFit: "contain",
  } as CSSProperties,
  border: {
    width: "61px",
    height: "61px",
    border: "1px solid #e9e9e9",
  },
  cat: {
    fontFamily: "VolvoNovum-Regular",
    textTransform: "uppercase",
    color: "#1151b5",
    fontWeight: "400",
    fontSize: "8px",
    marginBottom: "5px",
  } as CSSProperties,
  subcat: {
    fontFamily: "VolvoNovum-Regular",
    color: "#1e1f22",
    fontSize: "12px",
  } as CSSProperties,
  summaryDisclaimer: {
    fontFamily: "VolvoNovum-Regular",
    margin: "auto 60px 40px",
  },
  summaryDisclaimerTitle: {
    fontFamily: "VolvoNovum-Regular",
    fontSize: "8px",
    textTransform: "uppercase",
    paddingTop: "10px",
    paddingBottom: "10px",
    color: "#212529",
    textAlign: "center",
  } as CSSProperties,
  summaryDisclaimerText: {
    fontFamily: "VolvoNovum-Regular",
    fontSize: "10px",
    color: "#212529",
    textAlign: "center",
  } as CSSProperties,
  summaryCopyright: {
    fontFamily: "VolvoNovum-Regular",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    fontSize: "16px",
    textAlign: "center",
  } as CSSProperties,
  summaryCopyrightImg: {
    marginLeft: "12px",
    marginRight: "12px",
    display: "inline-block",
    maxWidth: "32px",
    height: "auto",
  },
  summaryCopyrightText: {
    fontFamily: "VolvoNovum-Regular",
    letterSpacing: "0.05em",
    fontSize: "14px",
    color: "#212529",
    fontWeight: "300",
  }
};