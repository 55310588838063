export interface mailFormDataTypes {
  firstname: string,
  lastname: string,
  email: string,
  phone: string,
  company: string,
  role:string,
  salesforceSegment: string,
  message: string,
  privacyCheckbox: boolean,
  marketingCheckbox: boolean,
}

export const mailFormData: mailFormDataTypes = {
  firstname: "",
  lastname: "",
  email: "",
  phone: "",
  company: "",
  role:"",
  salesforceSegment: "",
  message: "",
  privacyCheckbox: false,
  marketingCheckbox: false,
};