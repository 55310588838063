import { useState } from "react";
import { Route, Routes, useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { DataContainerContext } from "./common/context/DataContainerProvider";
import "./assets/scss/styles.scss";
// Pages
import { Segment } from "./pages/Segment/Segment";
import { Configurator } from "./pages/Configurator/Configurator";
import { Model } from "./pages/Model/Model";
import Markets from "./pages/Markets/markets";
import Summary from "./pages/Summary/Summary";
import { useContext, useEffect } from "react";
import DataContainer from "./services/DataContainer";
import { getLocales } from "./services/ApiService";
import { MainTextsSettings, MarketSettings } from "./enum/enums";
import { ArToWeb } from "./pages/ArToWeb/ArToWeb";

const isLocaleExisting = (locales: { locale: string }[], locale: string) => {
  return !!locales?.find((loc) => loc.locale === locale);
};

function App() {
  const {
    setCurrentLocale,
    setSearchParams,
    currentLocale,
    setDefaultVariantColor,
    setIsColorComingFromURL } = useContext(DataContainerContext);
  const navigate = useNavigate();
  const startTime = +new Date();
  const currentPath = useLocation();
  const [ searchParams ]: any = useSearchParams();
  const params = Object.fromEntries([...searchParams]);
  const endTime = +new Date();
  const pathname = currentPath.pathname.replace("/", "");
  const [availableLocales, setAvailableLocales] = useState([]);

  const isLocaleValid = (locales: {locale: string}[]) => {
    return locales.some((locale) => {
      return pathname.split("/")[0] === locale.locale;
    });
  };

  window.onload = function() {
    if(sessionStorage.getItem("endTime")){
      DataContainer.logEvent("session_end", "", {
        duration: parseInt(String((startTime - Number(sessionStorage.getItem(("endTime")))) / 1000)),
      });
    }
    sessionStorage.setItem("endTime", endTime.toString());
  };

  useEffect(() => {
    DataContainer.logEvent("session_start", "", {
      start_time: startTime,
      source: DataContainer.getDeviceType(),
    });

    if (availableLocales.length === 0) {
      getLocales().then((res) => setAvailableLocales(res.data.data)).catch(() => setAvailableLocales([]));
    }
  }, []);

  useEffect(() => {
    if(availableLocales.length === 0) {
      return;
    }

    const localeFromLocation = location.href.split("/").find((v)=> v.length == 5 && v.includes("-"));
    const localeFromURL = Object.prototype.hasOwnProperty.call(params, "lang") ? params.lang.toLowerCase() : "";

    if (localeFromLocation && isLocaleExisting(availableLocales, localeFromLocation) && (pathname === "markets" || pathname === "")) {
      navigate(`/${localeFromLocation}`);
    } else if (!isLocaleValid(availableLocales) && !currentPath.pathname.includes("get_configuration")) {
      navigate("/markets");
    }

    availableLocales.forEach((locale: { locale: string; id: string }) => {
      if (currentPath.pathname.includes("get_configuration") && locale.locale === localeFromURL) {
        setSearchParams(params);
        const color = Object.prototype.hasOwnProperty.call(params, "Exterior-Colors");
        color && setDefaultVariantColor(params["Exterior-Colors"]);
        color && setIsColorComingFromURL(true);
        setCurrentLocale({locale: locale.locale, localeID: locale.id});
        navigate(
          `/${localeFromURL}/configurator/${params.product.toLowerCase()}_rod`
        );
      } else if (pathname.split("/")[0] === locale.locale) {
        if(!currentLocale || currentLocale.locale !== locale.locale) {
          setCurrentLocale({locale: locale.locale, localeID: locale.id});
        }
        navigate(`/${pathname}`);
      }
    });

    if (pathname.replaceAll("/", "") !== "ar-to-web" && pathname.replaceAll("/", "") !== localeFromURL){
      return;
    }

    if (currentPath.pathname === "/" && currentLocale?.locale && pathname !== "ar-to-web") {
      navigate("/markets");
    }

  }, [pathname, availableLocales, DataContainer.sectionKey(MarketSettings.MARKET_SETTINGS, MarketSettings.CONFIGURATOR_URL)]);

  //================Temporary useEffect below===================
  useEffect(() => {
    const unavailableProducts = DataContainer.mProducts.filter((product: any) => product.id < 164).map((product: any) => product.product_info.description);
    const productName = pathname.split("/")[2];
    if(unavailableProducts.includes(productName)) {
      navigate("/markets");
    }
  }, [DataContainer.mProducts, pathname]);
  //============================================================

  return (
    <div className="flex h-full sm:h-screen flex-col justify-between flex-1">
      <Routes>
        <Route path="/markets" element={<Markets />} />
        <Route
          path="/:locale"
          element={<Segment title={DataContainer.sectionKey(MainTextsSettings.MAIN_TEXTS,MainTextsSettings.CHOOSE_SEGMENT )}/>}
        />
        <Route
          path="/:locale/:urlTitle/models"
          element={<Model title={DataContainer.sectionKey(MainTextsSettings.MAIN_TEXTS, MainTextsSettings.CHOOSE_MODEL )}/>}
        />
        <Route
          path="/:locale/configurator/:modelName"
          element={<Configurator />}
        />
        <Route path="/:locale/summary/:modelNameFromUrl/:hash" element={<Summary />} />
        <Route path="/:locale/get_configuration/:hash" element={<ArToWeb />}/>
      </Routes>
    </div>
  );
}

export default App;
