export enum MainSettings {
    SEGMENT_SETTINGS = "Segment.Settings",
    MAIN_SEGMENTS = "Main.Segments",
    SHOW_PRODUCTS = "show_products_",
    MAIN_CATEGORIES = "Main.Categories",
    MAIN_DISCLAIMER = "Main.Disclaimer",
}

export enum MarketSettings {
    MARKET_SETTINGS = "Market.Settings",
    STEERINGWHEEL_POSITION = "steeringwheel_position",
    CONFIGURATOR_URL = "configurator_url"
}

export enum DisclaimerTexts {
    MAIN_DISCLAIMER = "Main.Disclaimer",
    DISCLAIMER_TITLE = "disclaimer_title",
    DISCLAIMER_TEXT = "disclaimer_text",
    PRIVACY_BUTTON = "privacy_button",
    COOKIES_BUTTON = "cookies_button",
    PRIVACY_URL = "privacy_url",
    COOKIES_URL = "cookie_url",
    MARKETS_DISCLAIMER_TITLE = "Disclaimer",
    MARKETS_DISCLAIMER_TEXT = "Images and content in this application are for general guidance only and do not necessarily reflect the exact specifications or features of the model concerned. Confirm exact specifications, features and availability at your local Volvo Trucks dealer."
}

export enum MainTextsSettings {
    MAIN_TEXTS = "Main.Texts",
    CHOOSE_SEGMENT = "choose_segment",
    CHOOSE_MODEL = "choose_model",
    LOADING_TEXT = "Loading"
}
  
export enum MainCategories {
      SEGMENT_CATEGORY = "segment",
      SUMMARY_CATEGORY = "summary",
  }

export enum MainButtons {
    MAIN_BUTTONS = "Main.Buttons",
    NEXT_BUTTON = "next_button",
    BACK_BUTTON = "back_button",
    SIDEBAR_CLOSE_BUTTON = "sidemenu_close_text"
}

export enum MainTopMenus {
    MAIN_TOPMENU = "Main.TopMenu",
    YOUR_VOLVO = "your_volvo",
    OVERVIEW_TITLE = "overview_title"
}

export enum ContactDealerSettings {
    CONTACT_DEALER_SETTINGS = "ContactDealer.Settings",
    ACTIVE_AR_BUTTON = "active_ar_button",
    SHOW_CONTACT_FORM = "show_contact_form",
    ENABLE_FACEBOOK = "enable_facebook",
    SHOW_EMAIL_FOR_LOCALES = "show_email_for_locales",
    CAPTCHA_LABEL = "Enter the captcha..",
    NAME = "captcha",
    ACTIVE_CAMPAIGN = "active_campaign",
    SHOW_FOR_REGIONS = "show_for_regions",
    ACTIVE_APPSTORE_BUTTON = "active_appstore_button",
    ACTIVE_GOOGLESTORE_BUTTON = "active_googlestore_button",
    REGIONS_IN_FORM = "regions_in_form",
    SELECT_MARKET = "select_market",
    SHOW_CHOOSE_DEALER = "show_choose_dealer",
    SHOW_SHARE_BUTTON = "show_share_button",
    CONTACT_DEALER_LINK_BUTTON = "contact_dealer_link_button",
    SHOW_DOWNLOAD_PDF = "show_download_pdf",
    SHOW_DOWNLOAD_PRODUCT_IMAGE = "show_download_product_image",
    SHOW_ENVELOPE_ICON = "show_envelope_icon",
    REGIONS_LIST = "regions_list",
    SALESFORCE = "salesforce"
}

export enum SummarySettings {
    SUMMARY = "Summary",
    CONTACT_DEALER_BUTTON= "contact_dealer_button",
    OVERVIEW_CONFIGURATION = "overview_configuration",
    APP_STORE = "DOWNLOAD ON APP STORE",
    PLAY_STORE = "DOWNLOAD ON PLAY STORE",
}


export enum ContactDealerFormSettings {
    CONTACT_DEALER_FORM = "ContactDealerForm",
    CONTACT_DEALER_TITLE = "contact_dealer_title",
    CONTACT_DEALER_TEXT = "contact_dealer_text",
    CONTACT_DEALER_NAME_TEXT = "contact_dealer_name_text",
    CONTACT_DEALER_FIRSTNAME_TEXT = "contact_dealer_firstname_text",
    CONTACT_DEALER_LASTNAME_TEXT = "contact_dealer_lastname_text",
    CONTACT_DEALER_ESTABLISHMENT_TEXT = "contact_dealer_establishment_text",
    CONTACT_DEALER_EMAIL_TEXT = "contact_dealer_email_text",
    CONTACT_DEALER_PHONE_TEXT = "contact_dealer_phone_text",
    CONTACT_DEALER_COMPANY_TEXT = "contact_dealer_company_text",
    CONTACT_DEALER_SEGMENT_TEXT = "contact_dealer_segment_text",
    CONTACT_DEALER_SEGMENT_REQUIRED = "contact_dealer_segment_required",
    CONTACT_DEALER_SEGMENT_REQUIRED_TEXT = "contact_dealer_segment_required_text",
    CONTACT_DEALER_MESSAGE_TEXT = "contact_dealer_message_text",
    CONTACT_DEALER_REQUIRED_TIP_TEXT = "contact_dealer_required_tip_text",
    CONTACT_DEALER_CHOSEN_DEALER_TEXT = "contact_dealer_chosen_dealer_text",
    CONTACT_DEALER_SEND_TEXT = "contact_dealer_send_text",
    CONTACT_DEALER_SEGMENT_LIST = "contact_dealer_segment_list",
    CONTACT_DEALER_OPTIN_READ_AND_AGREE_PRIVACY_TEXT = "contact_dealer_optin_read_and_agree_privacy_text",
    CONTACT_DEALER_OPTIN_RECORD_BY_SELLER_TEXT = "contact_dealer_optin_record_by_seller_text",
    CONTACT_DEALER_OPTIN_VOLVO_TRUCKS_NEWS_TEXT = "contact_dealer_optin_volvo_trucks_news_text",
    CONTACT_DEALER_OPTIN_MARKETING_CHECKBOX = "contact_dealer_optin_marketing_checkbox",
    CONTACT_DEALER_OPTIN_MARKETING_CHECKBOX_TEXT = "contact_dealer_optin_marketing_checkbox_text",
    CONTACT_DEALER_OPTIN_MARKETING_CHECKBOX_REQUIRED = "contact_dealer_optin_marketing_checkbox_required",
    CONTACT_DEALER_OPTIN_MARKETING_CHECKBOX_REQUIRED_TEXT = "contact_dealer_optin_marketing_checkbox_required_text",
    CONTACT_DEALER_PRONOUN_TITLE = "contact_dealer_pronoun_title",
    CONTACT_DEALER_EMAIL_REQUIRED_TEXT = "contact_dealer_email_required_text",
    MESSAGE_SENT_TEXT = "message_sent_text",
    CONTACT_DEALER_NAME_REQUIRED = "contact_dealer_name_required",
    CONTACT_DEALER_FIRSTNAME_REQUIRED = "contact_dealer_firstname_required",
    CONTACT_DEALER_EMAIL_REQUIRED = "contact_dealer_email_required",
    CONTACT_DEALER_PHONE_REQUIRED = "contact_dealer_phone_required",
    CONTACT_DEALER_COMPANY_REQUIRED = "contact_dealer_company_required",
    CONTACT_DEALER_MESSAGE_REQUIRED = "contact_dealer_message_required",
    CONTACT_DEALER_CHOSEN_DEALER_REQUIRED_TEXT = "contact_dealer_chosen_dealer_required_text",
    CONTACT_DEALER_ACCEPT_TERMS_REQUIRED_TEXT = "contact_dealer_accept_terms_required_text",
    CONTACT_DEALER_EMAIL_INVALID_TEXT = "contact_dealer_email_invalid_text",
    CONTACT_DEALER_PRONOUN_MR_TEXT = "contact_dealer_pronoun_mr_text",
    CONTACT_DEALER_PRONOUN_MRS_TEXT = "contact_dealer_pronoun_mrs_text",
    CLOSE_BUTTON_TEXT = "close_button_text",
    CONTACT_DEALER_LASTNAME_REQUIRED = "contact_dealer_lastname_required",
    CONTACT_DEALER_ESTABLISHMENT_REQUIRED = "contact_dealer_establishment_required",
    CONTACT_DEALER_WRONG_CAPTCHA_TEXT="contact_dealer_wrong_captcha_text",
    CONTACT_DEALER_REGIONS_TEXT = "contact_dealer_regions_text",
    CONTACT_DEALER_SHOW_ROLE_LIST = "contact_dealer_show_role_list",
    CONTACT_DEALER_ROLE_TEXT = "contact_dealer_role_text",
    CONTACT_DEALER_ROLE_LIST = "contact_dealer_role_list",
    CONTACT_DEALER_ROLE_REQUIRED = "contact_dealer_role_required",
    CONTACT_DEALER_ROLE_LIST_REQUIRED_TEXT = "contact_dealer_role_list_required_text",
    SALESFORCE_SEGMENT_LIST = "salesforce_segment_list",
}


export enum PrivacyPolicySettings {
    PRIVACY_POLICY = "Main.PrivacyPolicy",
    PRIVACY_POLICY_TITLE = "privacy_policy_title",
    PRIVACY_POLICY_TEXT = "privacy_policy_text",
    PRIVACY_POLICY_CHECKBOX_TEXT = "privacy_policy_checkbox_text"
}
export enum PopupSendEmail {
    SEND_EMAIL= "Popup.SendEmail",
    SEND_EMAIL_TITLE = "send_email_title",
    SEND_EMAIL_TEXT = "send_email_text",
    SEND_EMAIL_CONFIRM_BUTTON = "send_email_confirm_button"
}
export enum PopupShare {
    POPUP_SHARE = "Popup.Share",
    SHARE_SENT_TEXT = "share_sent_text",
}
export enum alphabets {
    letters = "AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz",
}
export enum Disclaimer {
    DISCLAIMER_TITLE = "disclaimer_title",
    DISCLAIMER_TEXT = "disclaimer_text",
}


export enum Truck {
    NAME = "Volvo",
}

export enum HiddenOptions {
    HIDDEN_OPTIONS = "Hidden_options",
}

export enum ConfiguratorOptions {
    ENGINES = "Engines",
    TRANSMISSIONS = "Transmissions",
    CABS = "Cabs",
    EXTERIOR_TRIM = "Trim_levels",
    TRIM_LEVELS = "TRIM_LEVELS",
    HEADLIGHTS = "Headlights",
    AXLE_CONFIGURATIONS = "Chassis",
    INTERIOR = "Interior",
    EXTERIOR = "Exterior",
    MIRRORS = "Mirrors",
    COLORS = "Colors",
    CHASSI_TYPE = "Chassi_type",
    AGRA_RED_7518 = "agra_red_7518",
    SEATS = "Seats"
}

export enum RodSettings {
    ROD_SETTINGS = "rod-settings",
    ROD_ERROR = "rod_error"
}


export const DummyData = {
  name: "",
  lastname: "",
  email: "",
  phone: "",
  company: "",
  message: "",
  aanhef: "heer",
  pronounTitle: false,
  privacyText: false,
  sellerText: false,
  newsText: false,
  marketingCheckbox: false,
  customer_acct_name:"",
  segment: "",
  role: "",
  salesforceSegment: "",
  establishment: "",
  companyAddress: "",
  dealerData: {
    name: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    postcode: "",
    city: "",
    countryname: "",
    ctdiid: "",
    organizationid: ""
  },
};
