import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DataContainerContext } from "../../common/context/DataContainerProvider";
import { getLocales } from "../../services/ApiService";
import dataContainer from "../../services/DataContainer";
import DataContainer from "../../services/DataContainer";

export const ArToWeb = () => {
  const {
    data,
    currentLocale,
    setCurrentLocale
  } = useContext(DataContainerContext);
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(useLocation().search);
  const mandatoryFieldsFromAR = ["product", "Exterior-Cabs", "Exterior-Headlights",
    "Exterior-Trim_levels", "Exterior-Chassis", "Interior-Trim_levels", "Interior-Seats", "Exterior-Colors", "lang"];

  useEffect(()=>{
    localStorage.setItem("currentSelections", "");
    // validate that all params are set
    for(const field of mandatoryFieldsFromAR){
      if(!searchParams.has(field)){
        navigate("/markets");
      }
    }

    getLocales().then((res) => {
      const locale = res.data.data.find((l:any) => l.locale === searchParams.get("lang"));
      if(!locale){
        navigate("/markets");
      }
      setCurrentLocale({locale: locale.locale, localeID: locale.id});
    });

  }, []);

  useEffect(() => {
    data?.map((item: any) => {
      if (item.product_info.description === searchParams.get("product")?.toLocaleLowerCase()) {
        dataContainer.loadData(() => {
          dataContainer.instance();

          // let selections = [];
          // populate selection for each of variants
          for(const variationKey of mandatoryFieldsFromAR){
            //ignore product and lang
            if(["product", "lang"].includes(variationKey)){
              continue;
            }

            const variation = dataContainer.findVariationByOriginalKey(searchParams.get(variationKey));
            if(variation){
              //selections.push(variation.id);
              dataContainer.toggleVariationSelection(variation.id, "add", true);
            }
          }
          dataContainer.getSelectedVariations().map((variant) => {            
            const attribute = dataContainer.findAttributeFromAssets(variant);            
            if(attribute) {dataContainer.markAttributeAsSelected(attribute);}
          });
          // dataContainer.mSelectedVariations = selections as never[];
          DataContainer.redirectFromArToWebUrl(DataContainer.modifyTruckModel(item.product_info.description));
        },
        currentLocale,
        item.id,
        item.cached_url
        );
      }
    });
  }, [data, currentLocale]);

  return <div className="flex absolute z-50 flex-col items-center justify-center w-full h-full bg-white text-black">
    <div>...</div>
  </div>;
};